import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import ShopCard from "../components/reusable/ShopCard";
import Filters from "../components/reusable/Filters";
import Seo from "../components/global/Seo";

export default function Shop({ data }) {
  const products = data.products.nodes;
  const [activeClasses, setActiveClasses] = useState([]);

  const toggleActiveClasses = (e) => {
    let target;
    if (e.target.tagName === "BUTTON") {
      target = e.target.getAttribute("data-filter");
      console.log(target, activeClasses);
      if (activeClasses.includes(target)) {
        setActiveClasses([]);
      } else {
        setActiveClasses([target]);
      }
    }
    if (e.target.tagName === "SELECT") {
      target = e.target.options[e.target.selectedIndex].value;
      if (target === "*") {
      } else {
        setActiveClasses([target]);
      }
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      const cards = Array.from(document.querySelectorAll(".shop-card"));
      if (activeClasses.length > 0) {
        cards.forEach((card) =>
          Array.from(card.classList).some((r) => activeClasses.includes(r))
            ? card.classList.add("active")
            : card.classList.remove("active")
        );
      } else {
        cards.forEach((card) => card.classList.add("active"));
      }
    }
  }, [activeClasses]);

  return (
    <div className="page-shop">
      <Seo seo={data.page.seo} />
      <Filters
        toggleActiveClasses={toggleActiveClasses}
        activeClasses={activeClasses}
      />
      <h1 className="main-heading heading heading--x-large">Our Shop</h1>
      {/* <p style={{ textAlign: "center", paddingBottom: 100 }}>
        Our shop will be back online soon.... hang tight!! We're just fixing
        some issues for some of our users!
      </p> */}
      <div className="shop-grid">
        {products.map((product, index) => (
          <ShopCard
            activeClasses={activeClasses}
            key={`product_${index}`}
            product={product}
            showMoreInfoButton={true}
          />
        ))}
      </div>
    </div>
  );
}

export const ShopQuery = graphql`
  query ShopQuery {
    page: wpPage(title: { eq: "Shop" }) {
      seo {
        metaDesc
        twitterTitle
        twitterDescription
        opengraphDescription
        opengraphTitle
        title
        opengraphImage {
          publicUrl
        }
        twitterImage {
          publicUrl
        }
      }
    }
    products: allWpProduct(
      # filter: { acfDrinkInfo: { inStock: { gt: 0 } } }
      sort: { fields: menuOrder, order: ASC }
    ) {
      nodes {
        id
        databaseId
        title
        uri
        dontBuildPage {
          dontBuildAPageForThisProduct
        }
        acf_shopify {
          shopifyProductId
        }
        acfDrinkInfo {
          inStock
          shortDescription
          longDescription
          abv
          colour
          colourDark
          productPurchaseOptions {
            optionQuantity
            optionPrice
            optionNameCustom
            optionName
            quantityInStock
          }
        }
        acfBeerDrinkInfo {
          inStock
          longDescription
          biabPurchaseOptions {
            optionPrice
            quantityInStock
            chooseProduct {
              ... on WpProduct {
                title
              }
            }
          }
        }
        productCategories {
          nodes {
            slug
          }
        }
        acfBackgroundArt {
          backgroundArt {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        isGiftCard {
          isThisProductAGiftCard
        }
        acfCustomThumbnail {
          customThumbnail {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`;
