import React, { useEffect, useRef, useState } from "react";
import slugify from "../../helpers/slugify";
import SnipcartButton from "./SnipcartButton";
import SnipcartButtonGiftCard from "./SnipcartButtonGiftCard";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import MoreInfoButtonLightbox from "./MoreInfoButtonLightbox";

export default function SnipcartProductOptions({
  product,
  showMoreInfoButton,
}) {
  const [currentlySelected, setCurrentlySelected] = useState(0);
  const [qty, setQty] = useState(1);
  const [currentQty, setCurrentQty] = useState();
  const selectRef = useRef();
  const quantityRef = useRef();

  // const fetchAndUpdateQuantity = () => {
  //   var requestOptions = {
  //     method: "GET",
  //     redirect: "follow",
  //   };
  //   fetch(
  //     `${process.env.GATSBY_SITE_URL}/wp-json/wp/v2/products/${product.databaseId}`,
  //     requestOptions
  //   )
  //     .then((response) => response.json())
  //     .then((result) => setCurrentQty(result["acf"]["in_stock"]))
  //     .catch((error) => console.log("error", error));
  // };

  // useEffect(() => {
  //   fetchAndUpdateQuantity();
  //   const interval = setInterval(fetchAndUpdateQuantity, 1000 * 60 * 5);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // });

  return (
    <div className="product-options">
      {/* <label className="label label--small" htmlFor="product-options--select">
        Size
      </label>
      <div className="select-wrapper">
        <select
          ref={selectRef}
          name="product-options--select"
          id="product-options--select"
          className="product-options--select"
          onChange={(e) => {
            setCurrentlySelected(selectRef.current.selectedIndex);
            setQty(1);
          }}
        >
          {product?.acfBeerDrinkInfo?.biabPurchaseOptions === null
            ? product?.acfDrinkInfo?.productPurchaseOptions?.map(
                (option, index) =>
                  option.optionName === "Other" || option.optionNameCustom ? (
                    <option value={slugify(option.optionNameCustom)}>
                      {option.optionNameCustom} - £
                      {option.optionPrice.toFixed(2)}
                    </option>
                  ) : (
                    <option value={slugify(option.optionName)}>
                      {option.optionName}{" "}
                      {product.isGiftCard.isThisProductAGiftCard === true
                        ? ""
                        : `- £${option.optionPrice.toFixed(2)}`}
                    </option>
                  )
              )
            : product?.acfBeerDrinkInfo?.biabPurchaseOptions?.map(
                (option, index) => (
                  <option>
                    {option.chooseProduct.title} - £
                    {option.optionPrice.toFixed(2)}
                  </option>
                )
              )}
        </select>
        <BsChevronDown className="chevron" />
      </div>
      <label className="label label--small" htmlFor="product-options--quantity">
        Qty
      </label>
      {product?.acfDrinkInfo?.productPurchaseOptions?.map((option, index) =>
        currentlySelected === index ? (
          <div className="quantity-wrapper">
            <input
              type="number"
              readonly
              onFocus={(e) => e.preventDefault()}
              name="product-options--quantity"
              className="product-options--quantity"
              id="product-options--quantity"
              ref={quantityRef}
              max={
                option.quantityInStock
                  ? option.quantityInStock
                  : Math.floor(currentQty / option.optionQuantity)
              }
              min={1}
              value={qty}
            />

            <div className="chevron-wrapper">
              {option.quantityInStock > qty ? (
                <BsChevronUp
                  className="chevron-up merch"
                  onClick={() => setQty(qty + 1)}
                />
              ) : (
                <BsChevronUp
                  className="chevron-up disabled merch"
                  onClick={() => setQty(qty)}
                />
              )}
              {Math.floor(currentQty / option.optionQuantity) > qty ? (
                <BsChevronUp
                  className="chevron-up not-merch"
                  onClick={() => setQty(qty + 1)}
                />
              ) : (
                <BsChevronUp
                  className="chevron-up disabled not-merch"
                  onClick={() => setQty(qty)}
                />
              )}
              <BsChevronDown
                className={`chevron-down ${qty === 1 ? "disabled" : ""}`}
                onClick={() => (qty > 1 ? setQty(qty - 1) : setQty(qty))}
              />
            </div>
          </div>
        ) : (
          ""
        )
      )} */}

      {/* <div className="buttons-wrapper">
        {product?.acfBeerDrinkInfo?.biabPurchaseOptions === null
          ? product?.acfDrinkInfo?.productPurchaseOptions?.map(
              (option, index) => {
                if (product?.isGiftCard?.isThisProductAGiftCard === true) {
                  return (
                    <SnipcartButtonGiftCard
                      disabled={currentlySelected !== index ? true : false}
                      outOfStock={currentQty <= 0 ? true : false}
                      product={product}
                      current={option}
                      qty={qty}
                      index={index}
                      currentQty={currentQty}
                    />
                  );
                } else {
                  return (
                    <SnipcartButton
                      disabled={currentlySelected !== index ? true : false}
                      outOfStock={currentQty <= 0 ? true : false}
                      product={product}
                      current={option}
                      qty={qty}
                      index={index}
                      currentQty={currentQty}
                    />
                  );
                }
              }
            )
          : product?.acfBeerDrinkInfo?.biabPurchaseOptions?.map(
              (option, index) => (
                <SnipcartButton
                  disabled={currentlySelected !== index ? true : false}
                  outOfStock={currentQty <= 0 ? true : false}
                  product={product}
                  current={option}
                  qty={qty}
                  index={index}
                  biabTitle={option.chooseProduct.title}
                  currentQty={currentQty}
                />
              )
            )}
        {showMoreInfoButton === true ? (
          <MoreInfoButtonLightbox product={product} />
        ) : (
          ""
        )}
      </div> */}

      {/* <div className="max-qty" style={{ display: "none" }}>
        <p>{`Current stock level:${currentQty}`}</p>
      </div> */}
    </div>
  );
}
