import React, { useEffect, useRef, useState } from "react";
import slugify from "../../helpers/slugify";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import MoreInfoButtonLightbox from "./MoreInfoButtonLightbox";
import { ShopifyContext } from "../../context/ShopifyContext";
import { useContext } from "react";

export default function ShopifyProductOptions({
  product,
  showMoreInfoButton,
  setIsAvailable,
  isAvailable,
}) {
  const [currentlySelected, setCurrentlySelected] = useState(0);
  const [qty, setQty] = useState(1);
  const selectRef = useRef();
  const quantityRef = useRef();

  const { client, cartContents, updateLineItems } = useContext(ShopifyContext);

  const [variants, setVariants] = useState();
  const [newVariants, setNewVariants] = useState();

  useEffect(() => {
    const productId = `gid://shopify/Product/${product.acf_shopify.shopifyProductId}`;
    if (!product.acf_shopify.shopifyProductId && variants === undefined) {
      setIsAvailable(false);
    } else if (variants === undefined && product.acf_shopify.shopifyProductId) {
      client.product.fetch(productId).then((product) => {
        // Do something with the product

        setVariants(product);
        if (product.availableForSale === true || product.available === true) {
          setIsAvailable(true);
        } else {
          setIsAvailable(false);
        }
      });
    }
  });

  useEffect(() => {
    if (variants) {
      const availableVariants = variants.variants.filter((variant) => {
        if (variant.available) {
          return variant;
        }
      });

      setNewVariants(availableVariants);
    }
  }, [variants]);
  useEffect(() => {
    if (newVariants) {
      console.log(newVariants);
    }
  }, [newVariants]);

  useEffect(() => {
    // console.log(product.variants[currentlySelected]);
    if (currentlySelected) {
      console.log(variants.variants);
      console.log(variants.variants[currentlySelected]);
    }
  }, [currentlySelected]);

  return (
    <>
      {isAvailable === true ? (
        <>
          <div className="product-options">
            <label
              className="label label--small"
              htmlFor="product-options--select"
            >
              Size
            </label>
            <div className="select-wrapper">
              <select
                ref={selectRef}
                name="product-options--select"
                id="product-options--select"
                className="product-options--select"
                onChange={(e) => {
                  // console.log(selectRef.current.selectedIndex);
                  setCurrentlySelected(selectRef.current.selectedIndex);
                  setQty(1);
                }}
              >
                {variants
                  ? variants?.variants.map((variant, index) =>
                      variant.available ? (
                        <option
                          data-index={index}
                          key={`shopify_option_${index}`}
                        >{`${variant.title} - £${parseFloat(
                          variant.price.amount
                        ).toFixed(2)}`}</option>
                      ) : (
                        ""
                      )
                    )
                  : ""}
              </select>
              <BsChevronDown className="chevron" />
            </div>
            <label
              className="label label--small"
              htmlFor="product-options--quantity"
            >
              Qty
            </label>
            <div className="quantity-wrapper">
              {newVariants
                ? newVariants.map((variant, index) =>
                    index === currentlySelected ? (
                      <>
                        <input
                          type="number"
                          readOnly
                          onFocus={(e) => e.preventDefault()}
                          name="product-options--quantity"
                          className="product-options--quantity"
                          id="product-options--quantity"
                          ref={quantityRef}
                          // max={
                          //   option.quantityInStock
                          //     ? option.quantityInStock
                          //     : Math.floor(currentQty / option.optionQuantity)
                          // }
                          min={1}
                          value={qty}
                        />
                        <div className="chevron-wrapper">
                          <BsChevronUp
                            className="chevron-up"
                            onClick={() => setQty(qty + 1)}
                          />
                          <BsChevronDown
                            className={`chevron-down`}
                            onClick={() =>
                              qty > 1 ? setQty(qty - 1) : setQty(qty)
                            }
                          />
                        </div>
                      </>
                    ) : (
                      ""
                    )
                  )
                : ""}
            </div>
            <div className="buttons-wrapper">
              <ShopifyContext.Consumer>
                {({ handleAddToCart }) =>
                  newVariants
                    ? newVariants.map((variant, index) =>
                        index === currentlySelected ? (
                          <button
                            key={`shopify_button_${index}`}
                            className="btn btn--black"
                            onClick={() => {
                              handleAddToCart(
                                variant.id,
                                qty,
                                product.acfCustomThumbnail.customThumbnail
                                  .localFile.publicURL
                              );
                            }}
                          >
                            Add to cart
                          </button>
                        ) : (
                          ""
                        )
                      )
                    : ""
                }
              </ShopifyContext.Consumer>
              {showMoreInfoButton === true ? (
                <MoreInfoButtonLightbox product={product} />
              ) : (
                ""
              )}
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
}
