import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ShopifyProductOptions from "./ShopifyProductOptions";
import SnipcartProductOptions from "./SnipcartProductOptions";

const BackgroundColour = styled.div`
  .gatsby-image-wrapper {
    background: radial-gradient(
      ${(props) => props.color1},
      ${(props) => props.color2}
    );
  }
`;

export default function ShopCard({
  product,
  activeClasses,
  showMoreInfoButton,
  dontScale,
}) {
  const [isAvailable, setIsAvailable] = useState(undefined);
  const [hide, setHide] = useState();

  useEffect(() => {
    if (isAvailable === true) {
      setHide(false);
    } else {
      setHide(true);
    }
  }, [isAvailable]);

  return (
    <article
      className={`${
        hide ? "forceHide" : ""
      } shop-card active all ${product?.productCategories?.nodes?.map(
        (slug) => slug.slug + " "
      )}`}
    >
      <BackgroundColour
        color1={product.acfDrinkInfo.colour}
        color2={product.acfDrinkInfo.colourDark}
      >
        {/* {currentQty < 50 && currentQty > 0 ? (
          <label className="low-stock tag-label">Low stock... Order now!</label>
        ) : (
          ""
        )} */}
        <GatsbyImage
          className={`productImage ${dontScale === true ? "dont-scale" : ""}`}
          backgroundColor="transparent"
          image={
            product.acfCustomThumbnail.customThumbnail.localFile.childImageSharp
              .gatsbyImageData
          }
        />
      </BackgroundColour>

      {product.dontBuildPage.dontBuildAPageForThisProduct !== true ? (
        <Link to={product.uri}>
          <h2 className="heading heading--small">{product.title}</h2>
        </Link>
      ) : (
        <h2 className="heading heading--small">{product.title}</h2>
      )}

      <div
        className={`abv heading heading--x-small ${
          product.acfDrinkInfo.abv ? "" : "transparent"
        }`}
      >
        {product.acfDrinkInfo.abv ? product.acfDrinkInfo.abv : "0"}
      </div>
      <ShopifyProductOptions
        product={product}
        showMoreInfoButton={showMoreInfoButton}
        setIsAvailable={setIsAvailable}
        isAvailable={isAvailable}
      />
    </article>
  );
}
