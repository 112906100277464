import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { BsMenuButton, BsChevronDown } from "react-icons/bs";
import { GrFilter } from "react-icons/gr";

export default function Filters({ data, activeClasses, toggleActiveClasses }) {
  return (
    <StaticQuery
      query={graphql`
        query Filters {
          allWpProductCategory(sort: { order: DESC, fields: [count] }) {
            nodes {
              slug
              name
              count
            }
          }
        }
      `}
      render={(data) => (
        <nav className="filters">
          <label className="heading heading--x-small">
            <span className="filter-icon">
              <span className="number"></span>
              <GrFilter />
            </span>
            <span className="filter-text">Filter by:</span>
          </label>
          {data.allWpProductCategory.nodes.map((filter, index) =>
            filter.count !== null ? (
              <button
                onClick={toggleActiveClasses}
                key={`filter_${index}`}
                className={`btn btn--black ${
                  activeClasses.includes(filter.slug) ? "active" : ""
                }`}
                data-filter={filter.slug}
              >
                {filter.name}
                {activeClasses.includes(filter.slug) ? (
                  <span className="close">X</span>
                ) : (
                  ""
                )}
              </button>
            ) : (
              ""
            )
          )}

          <div className="select-wrapper">
            <select id="mobile-filter" onChange={toggleActiveClasses}>
              <option value={`all`}>All products</option>
              {data.allWpProductCategory.nodes.map((filter, index) =>
                filter.count !== null ? (
                  <option key={`filter_${index}`} value={filter.slug}>
                    {filter.name}
                  </option>
                ) : (
                  ""
                )
              )}
            </select>
            <BsChevronDown />
          </div>
        </nav>
      )}
    />
  );
}
