import React, { useRef } from "react";
import { GrClose } from "react-icons/gr";
import parse from "html-react-parser";
import SnipcartProductOptions from "./SnipcartProductOptions";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";

// const ImageBackground = styled.div`
//   background: ${(props) => props.bg};
// `;

export default function MoreInfoButtonLightbox({ product }) {
  const {
    abv,
    beerType,
    colour,
    colourDark,
    longDescription,
    shortDescription,
  } = product.acfDrinkInfo;
  const lightboxRef = useRef();
  const handleClick = (e) => {
    if (lightboxRef.current.classList.contains("visible")) {
      lightboxRef.current.classList.remove("visible");
    } else {
      lightboxRef.current.classList.add("visible");
    }
  };

  return (
    <>
      <button className={`btn btn--black`} onClick={(e) => handleClick(e)}>
        More Info
      </button>

      <div ref={lightboxRef} className="lightbox">
        <GrClose className="close" onClick={(e) => handleClick(e)} />
        <div className="lightbox-inner">
          {/* <ImageBackground className="image" bg={colour} bg2={colourDark}>
            <GatsbyImage
              className="productImage"
              onLoadStart={() => console.log("loading image")}
              backgroundColor="transparent"
              object-fit={"contain"}
              image={
                product.acfCustomThumbnail.customThumbnail.localFile
                  .childImageSharp.gatsbyImageData
              }
            />
          </ImageBackground> */}
          <div className="info">
            <h1 className="heading heading--x-large">{product.title}</h1>
            <div className="short-desc-text text text--small">
              {shortDescription}
            </div>
            <div className="long-desc-text text text--small">
              {longDescription ? parse(longDescription) : ""}
            </div>

            {abv ? (
              <div className="beer-info">
                <div className="beer-info__item single-beer__beer-info--abv">
                  <label htmlFor="abv" className="heading heading--x-small">
                    ABV
                  </label>
                  <span className="abv text text--small">{abv}</span>
                </div>
              </div>
            ) : (
              ""
            )}
            {product.acfDrinkInfo.productPurchaseOptions !== null ? (
              <SnipcartProductOptions product={product} />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
}
